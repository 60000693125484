import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
export const MainTheme = createTheme({
	palette: {
		type: 'dark',
		main: {
			main: '#4a53d7',
		},
		card: {
			main: "#414141",
		},
		primary: {
			main: '#4a53d7',
		},
		secondary: {
			main: '#f8c63f',
		},
		background: {
			default: '#181818',
			paper: '#282828',
		},
		text: {
			primary: '#d7d7d7',
		},
		divider: 'rgba(219,219,219,0.12)',
	},
	typography: {
		fontSize: 14,
		fontFamily: 'Courier New',
		h1: {
			fontSize: '2.75rem',
			fontWeight: 100,
		},
		h2: {
			fontSize: '2.25rem',
			fontWeight: 100,
		},
		h3: {
			fontSize: '1.75rem',
			fontWeight: 100,
		},
		h4: {
			fontSize: '1.5rem',
			fontWeight: 100,
		},
		h5: {
			fontSize: '1.25rem',
			fontWeight: 100,
		},
		h6: {
			fontSize: '1rem',
			fontWeight: 100,
		},
		subtitle1: {
			fontSize: '0.8rem',
		},
		subtitle2: {
			fontSize: '0.8rem',
		},
		body1: {
			fontSize: '0.75rem',
			fontWeight: 50,
		},
		body2: {
			fontSize: '0.65rem',
			fontWeight: 50,
		},
		button: {
			fontSize: '1rem',
			fontWeight: 350,
		},
		caption: {
			fontSize: '0.6rem',
		},
		overline: {
			fontSize: '0.65rem',
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	
		<React.StrictMode>
			<ThemeProvider theme={MainTheme}>
				<App />
			</ThemeProvider>,
		</React.StrictMode>,
	
	document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();
