import React, { useState, useEffect, useCallback, Fragment, useMemo } from 'react';
import ReactFlow, {Handle, Position, useNodesState, useEdgesState, addEdge, MiniMap, Controls, Background} from 'react-flow-renderer';
import TextField from '@mui/material/TextField';
import { borderColor } from '@mui/system';

const initBgColor = '#1A192B';

const connectionLineStyle = { stroke: '#fff' };
const snapGrid = [20, 20];

const handleStyle = { left: 10 };

function NumberInputNode({ data }) {

	const onChange = useCallback((evt) => {
		console.log(evt.target.value);
	}, []);
	
	return (
		<div className="text-updater-node">
		<Handle type="target" position={Position.Top} />
		<div>
			<label htmlFor="text">Text:</label>
			<input id="text" name="text" onChange={onChange} />
		</div>
		<Handle type="source" position={Position.Bottom} id="a" style={handleStyle} />
		<Handle type="source" position={Position.Bottom} id="b" />
		</div>
	);
}

const onLoad = (reactFlowInstance) =>  {
	reactFlowInstance.fitView();
}

const AdvertisementSpending = () => {

	const nodeTypes = useMemo(() => ({ numberInput: NumberInputNode }), []);

	const initialNodes = [
		{
			id: '1',
			type: 'numberInput',
			style: { 
				background: "#1A192B",
				padding: "5px",
			},
			data: { label: <TextField
				// required
				id="outlined-number"
				label="Server Size"
				type="number"
				variant="standard"
				value={12}
				inputLabelProps = {{
					background: "background.paper",
					padding: "0px",
				}}
				sx = {{
					background: "background.paper",
					color: "text.primary",
					".MuiFormLabel-focused": {
						color: "green"
					},
					".MuiFormLabel-filled": {
						color: "text.primary"
					},
					".MuiFormLabel-root": {
						color: "text.primary"
					},
				}}
			   />},
			position: { x: 250-250, y: 25-250 },
		},
		{
			id: '2',
			// you can also pass a React component as a label
			data: { label: <div>Default Node</div> },
			position: { x: 100-250, y: 125-250 },
		},
		{
			id: '3',
			type: 'output',
			data: { label: 'Output Node' },
			position: { x: 250-250, y: 250-250 },
		},
	];
	   
	const initialEdges = [
		{
			id: 'e1-2', 
			source: '1', 
			target: '2' 
		},
		{
			id: 'e2-3', 
			source: '2', 
			target: '3', 
			animated: true
		},
	];
	

	const [nodes, setNodes] = useState(initialNodes);
	const [edges, setEdges] = useState(initialEdges);

	return(
		<Fragment>
			<ReactFlow
				onLoad={onLoad}
				nodes={nodes} 
				edges={edges}
				style={{
					width:'100%',
					height: '100vh',
					background: '#282828',
				}}
				connectionLineStyle={{stroke: "#ddd", strokeWidth: 2}}
				connectionLineType = "bezier"
				snapToGrid = {true}
				snapGrid={[10,10]}
				minZoom = {2}
				maxZoom = {2}
				preventScrolling = {true}
				defaultPosition = {[0,0]}
				translateExtent = {[[-0,-100],[0,-100]]}
				fitView = {true}
			>
				{/* <Background 
					color="#4f5b62" 
					gap={20}
				/> */}
			</ReactFlow>
		</Fragment>
	)
}

export default AdvertisementSpending;