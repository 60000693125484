import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import HomeIcon from '@mui/icons-material/Home';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import HandymanIcon from '@mui/icons-material/Handyman';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PersonIcon from '@mui/icons-material/Person';
import TwitterIcon from '@mui/icons-material/Twitter';

import{Link} from "react-router-dom";

const drawerWidth = 220;

const AppBar = styled(MuiAppBar, {
		shouldForwardProp: (prop) => prop !== 'open',
	})
	(
		({ theme, open }) => ({
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			...(open && {
				width: `calc(100% - ${drawerWidth}px)`,
				marginLeft: `${drawerWidth}px`,
				transition: theme.transitions.create(['margin', 'width'], {
					easing: theme.transitions.easing.easeOut,
					duration: theme.transitions.duration.enteringScreen,
				}),
			}),
		})
	);

	const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

export default function NavMenu() {
	const [title, setTitle] = React.useState("Home");


	class NavButton extends React.Component {
		render(){
			return (
				<ListItem 
					key = {this.props.text} 
					disablePadding 
					component={Link} 
					to={this.props.path}
					onClick = {() => {setTitle(this.props.text)}}
				>
					<ListItemButton 
						sx={{color: "white"}}
					>
						<ListItemIcon sx={{color: "common.white"}}>
							{this.props.icon}
						</ListItemIcon>
						<ListItemText 
							primaryTypographyProps={{
								fontSize: '22px', 
								fontFamily: ["Courier New"]
							}} 
							primary={this.props.text} 
						/>
					</ListItemButton>
				</ListItem>
			)
		}
	}
	
	
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<AppBar position="fixed" open={open}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						sx={{ mr: 2, ...(open && { display: 'none' }) }}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h3" noWrap component="div">
						{title}
					</Typography>
				</Toolbar>
			</AppBar>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
				variant="persistent"
				anchor="left"
				open={open}
			>
				<DrawerHeader>
					<IconButton sx={{color: "text.primary"}} onClick={handleDrawerClose}>
					{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
					</IconButton>
				</DrawerHeader>
				<Divider />
				<List>
					<NavButton text="Home" setTitle = {setTitle} path = "/" icon = <HomeIcon />/>
					<NavButton text="Games" setTitle = {setTitle} path = "/games" icon = <VideogameAssetIcon />/>
					<NavButton text="Tools" setTitle = {setTitle} path = "/tools" icon = <HandymanIcon />/>
					<ListItem>
						<List dense disablePadding={true} sx={{ display: 'flex', align: 'center'}}>
							<ListItem>
								<IconButton
									color="inherit"
									aria-label="Go to YouTube Channel"
									edge="start"
									target="_blank" 
									sx = {{
										padding: "0px"
									}}
									href="https://www.youtube.com/c/NightcycleLLC"
								>
									<YouTubeIcon />
								</IconButton>
							</ListItem>
							<ListItem>
								<IconButton
									color="inherit"
									aria-label="Go to Twitter"
									edge="start"
									target="_blank" 
									sx = {{
										padding: "0px"
									}}
									href="https://twitter.com/Nightcycle_LLC"
								>
									<TwitterIcon />
								</IconButton>
							</ListItem>
							<ListItem>
								<IconButton
									color="inherit"
									aria-label="Go to Discord"
									edge="start"
									target="_blank"
									sx = {{
										padding: "0px"
									}}
									href="https://discord.gg/3FQmzDu"
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-discord" viewBox="0 0 16 16">
										<path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
									</svg>
								</IconButton>
							</ListItem>
							<ListItem>
								<IconButton
									color="inherit"
									aria-label="Go to Roblox"
									edge="start"
									target="_blank"
									sx = {{
										padding: "0px"
									}}
									href="https://www.roblox.com/groups/4181328/Nightcycle#!/about"
								>
									<svg 
									xmlns="http://www.w3.org/2000/svg" 
									id="footer-sample-full" 
									width="30" 
									height="30" 
									preserveAspectRatio="xMidYMid meet" 
									viewBox="0 0 24 24" 
									class="iconify iconify--simple-icons"
									>
										<path fill="currentColor" d="m13.383 14.341l-3.726-.958l.959-3.726l3.726.959l-.96 3.726zM4.913 0L0 19.088L19.088 24L24 4.912L4.912 0z"></path>
									</svg>
								</IconButton>
							</ListItem>
						</List>
					</ListItem>
				</List>
			</Drawer>
		</Box>
	);
}
