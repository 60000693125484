import React from 'react';
import '../App.css';
import Typography from '@mui/material/Typography';
import AdvertisementSpending from '../flows/AdvertisementSpending.jsx'

function Resources() {
	return(
		<div>
			<Typography 
				variant="h5" 
				component="div" 					
				align="left"
				gutterBottom
				sx = {{
					color: "text.primary"
				}}
			>
				<b>Coming eventually, probably</b>
			</Typography>
			<AdvertisementSpending/>
		</div>
	);
}

export default Resources;