import React from 'react';
import '../App.css';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

function About() {
	return(
		<body class="main">
			<List dense disablePadding={true} sx={{ display: 'flex', align: 'center'}}>
				<ListItem>
					<div>
						<Typography 
							variant="h3" 
							component="div" 					
							align="left"
							gutterBottom
							sx = {{
								color: "text.primary"
							}}
						>
							<b>We love making games</b>
						</Typography>

						<Typography 
							variant="body1" 
							component="div" 					
							align="left"
							gutterBottom
							sx = {{
								color: "text.primary"
							}}
						>
							Nightcycle Studios was founded for one reason - brands are cool and we wanted one. Also we had to call the LLC something. It also allowed the founder to roleplay as a professional and use the pronoun "we" instead of "me", even if there is only one employee.
						</Typography>

						<Typography 
							variant="body1" 
							component="div" 					
							align="left"
							gutterBottom
							sx = {{
								color: "text.primary"
							}}
						>
							Nightcycle is not trying hit the front page, or get a ton of funding to expand to dozens of employees. It's trying to produce, and occasionally release, games that are as fun to play as they are to make. We don't want to trade out work we love for work that lets us retire by 30.
						</Typography>

						<Typography 
							variant="body1" 
							component="div" 					
							align="left"
							gutterBottom
							sx = {{
								color: "text.primary"
							}}
						>
							We love to create social games with mechanics that encourage creativity from the players. We also love procedural generation and destruction. We love making cool analytics dashboards too. We're not sure how we feel about making websites - it takes a lot of work and let's be honest this one's pretty mid.
						</Typography>
					</div>
				</ListItem>
				<ListItem>
					<div>
						<img 
							src = "https://res.cloudinary.com/startup-grind/image/upload/c_fill,dpr_2.0,f_auto,g_center,h_200,q_auto:good,w_200/v1/gcs/platform-data-roblox/events/chrome_Gllnn4bTRx_fkQ8bVb.png"
						/>
						<Typography 
							variant="subtitle1" 
							component="div" 					
							align="center"
							sx = {{
								color: "text.primary"
							}}
						>
							<b>CJ Oyer</b>
						</Typography>
						<Typography 
							variant="caption" 
							component="div" 					
							align="center"
							sx = {{
								color: "text.primary"
							}}
						>
							Founder, director, person currently writing this caption.
						</Typography>
					</div>
					
				</ListItem>
			</List>
			
		</body>
	);
}

export default About;