import React from 'react';
import '../App.css';
import Typography from '@mui/material/Typography';

function NoPage() {
	return(
		<Typography 
			variant="h5" 
			component="div" 					
			align="left"
			gutterBottom
			sx = {{
				color: "text.primary"
			}}
		>
			<b>Pretend you didn't see this 404 page</b>
		</Typography>
	);
}

export default NoPage;