import React from "react";
import {Outlet} from "react-router-dom";
import NavMenu from "../components/NavMenu.jsx";


// import CssBaseline from '@mui/material/CssBaseline';
import '../App.css';

const Layout = () => {
	return (
		<div class = "layout" style={{
			display: "flex", 	
			padding: "20px",
			paddingLeft: "10%",
			paddingRight: "10%",
			paddingTop: "50px",
		}}>
			<div
				 class = "sideNavPadding"
				style={{
					padding: "0px",
					width: "0px",
				}}
			>
				<div class="sidenav"><NavMenu /></div>
			</div>
			<div 
			 	class = "main-container"
				style={{
					background: "#101010",
					flex: 1, 
					padding: "15px"
				}}>
				<Outlet/>
			</div>
		</div>
	);

};

export default Layout;