import React from 'react';
import '../App.css';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
 

class ProjectProfile extends React.Component {

	playButton(){
		if (this.props.playable == "true"){
			return (
				<Button size="small" variant="outlined" target="_blank" href={this.props.link} sx = {{color: "primary.light", padding: 0}}>
					<b>PLAY</b>
				</Button>
			)
		}
	}

	render(){
		return (
			<ImageListItem key={this.props.index}>
				<Card className="card" variant="elevated" sx={{
						maxWidth: "100%", 
						background: "card.main",
						
					 }}>
					<CardMedia
						variant = "outlined"
						sx = {{
							// border: "1px solid background.default",
							objectPosition: "100% -20px",
							objectFit: "cover",
							maxHeight: "150px"
						}}
						component="img"
						alt={this.props.alt}
						// height="140"
						image={this.props.image}
					/>
					<Accordion sx={{ minHeight: 0 }}>
						<AccordionSummary 
							expandIcon={<ExpandMoreIcon sx = {{color: "text.primary"}} />}
							// disableGutters={true}
							
							sx = {{
								
							}}
						>
							<Typography noWrap variant="h5" component="div">
								<b>{this.props.name}</b>
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<CardContent sx = {{padding: 0, paddingBottom: "15px"}}>
								<Divider variant = "fullWidth" orientation="horizontal" component="li">
									<Typography  
										variant="caption" 
										// fontSize="12px" 
										// fontFamily={"Courier New"} 
										component="div" 
										align="left">
										{this.props.year}
									</Typography>
								</Divider>
								<Typography variant="body2">
									{this.props.description}
								</Typography>
							</CardContent>
							<CardActions sx = {{padding: 0}}>
								{this.playButton()}
							</CardActions>
						</AccordionDetails>
					</Accordion>
				</Card>
			</ImageListItem >
		);
	}
}



function Projects() {
  return (
//     <Box sx={{ flexGrow: 1 }} container justifyContent="center">
      <ImageList sx={{ flexGrow: 1 }} variant="standard" cols={3} gap={12}>
		<ProjectProfile 
			alt = "Infrastruct thumbnail"
			year = "2022"
			image = "https://media.discordapp.net/attachments/981770495227752470/985672300315672596/unknown.png"
			name = "Infrastruct"
			description = "An infrastructure building real-time strategy tycoon. This is the first Nightcycle game with an education angle, hoping to inform players on the costs and benefits of different types of infrastructure as well as inspired future civil engineers."
			playable = "false"
			index = {1}
		/>
		<ProjectProfile 
			alt = "SHL4 thumbnail"
			year = "TBD"
			image = "https://cdn.discordapp.com/attachments/465275871381422101/917871883167334400/icon_copy.jpg"
			name = "Super Hero Life IV"
			description = "The long-awaited 4th entry to the SHL franchise, evolving over time to incorporate elements of procedurally generated world-building and story-telling while providing the best character customization the series has ever seen."
			playable = "false"
			index = {2}
		/>
		<ProjectProfile 
			alt = "Colossus Valley Thumbnail"
			year = "2021"
			image = "https://tr.rbxcdn.com/d0e7d1038cc8c43337d37c45761b35b7/768/432/Image/Png"
			name = "Colossus Valley"
			description = "A chaotic physics driven survival game where players try to survive the wrath of a telekinetic giant. The most recent game released after a week-long game jam."
			playable = "true"
			link = "https://www.roblox.com/games/6683160653"
			index = {3}
		/>
		<ProjectProfile 
			alt = "Cloudside Thumbnail"
			year = "2021"
			image = "https://tr.rbxcdn.com/1bae62d984b0bb7ddc96e1d3e393b829/768/432/Image/Png"
			name = "Cloudside"
			description = "A naval survival crafting game where players must team up to keep their flying ship from crashing while fending off waves of flying monsters. This project was funded by Supersocial to see what a game made in 2 weeks would look like."
			playable = "true"
			link = "https://www.roblox.com/games/6806060211"
			index = {4}
		/>
		<ProjectProfile 
			alt = "Slider Infinity Thumbnail"
			year = "2021"
			image = "https://tr.rbxcdn.com/98e61932fd814ac9f089be5da11c278c/768/432/Image/Png"
			name = "Slider Infinity"
			description = "A fast-paced social platformer where a neon track is dynamically constructed off the votes of players. This project wass a 1 month collaboration with Sliververse Games."
			playable = "true"
			link = "https://www.roblox.com/games/6288298661"
			index = {5}
		/>
		<ProjectProfile 
			alt = "NS Thumbnail"
			year = "2020"
			image = "https://devforum-uploads.s3.dualstack.us-east-2.amazonaws.com/uploads/original/4X/4/0/0/4000db5ef5c1c028a6199f7ccb0ee6738d433503.jpeg"
			name = "Nightships"
			description = "A procedurally generated open-galaxy ship-building game. One of the most technically impressive entries in the catalog, however limited in commercial viability as it never graduated the tech demo stage."
			playable = "true"
			link = "https://www.roblox.com/games/3723905084"
			index = {6}
		/>

		<ProjectProfile 
			alt = "MM Thumbnail"
			year = "2019"
			image = "	https://tr.rbxcdn.com/1f1b378911c88b0f5282e54c9a30258b/768/432/Image/Png"
			name = "Mortal Metal"
			description = "A PvE robot fighting game set in the post apocalpyse. It is the most graphically demanding game released by Nightcycle due to both its immense scale and focus on immersive art direction."
			playable = "true"
			link = "https://www.roblox.com/games/3133472730"
			index = {7}
		/>
		<ProjectProfile 
			alt = "SHL3 Thumbnail"
			year = "2018"
			image = "https://tr.rbxcdn.com/383611edc6599c71f58369cb7bde3617/768/432/Image/Png"
			name = "Super Hero Life III"
			description = "The third entry in the SHL franchise, pivoting more towards action-adventure while still catering to a limited roleplay audience."
			playable = "true"
			link = "https://www.roblox.com/games/2015093382"
			index = {8}
		/>	
		<ProjectProfile 
			alt = "SHL2 Thumbnail"
			year = "2017"
			image = "https://tr.rbxcdn.com/4c162f71f9b0fcee091a2a77eb0c8796/768/432/Image/Png"
			name = "Super Hero Life II"
			description = "A super-themed roleplaying game released in released in 2017 as a result of the Roblox summer accelerator program. It went on to be the most played game in Nightcycle's history."
			playable = "true"
			link = "https://www.roblox.com/games/508693446"
			index = {9}
		/>		
      </ImageList>
//     </Box>
  );
}

export default Projects;