// import React from 'react';

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Games from "./pages/Games";
import Tools from "./pages/Tools";
import NoPage from "./pages/NoPage";
import React from "react";

import './App.css';

export default function App() {
  return (
	<div>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<Home />} />
					<Route path="tools" element={<Tools />} />
					<Route path="games" element={<Games />} />
					<Route path="*" element={<NoPage />} />
				</Route>
			</Routes>
		</BrowserRouter>
	</div>
  );
}
